import { Info } from "@mui/icons-material";
import { Box, Card, CardContent, CardHeader, Chip, FormControl, Grid, IconButton, MenuItem, Select, Typography } from "@mui/material";
import MiniStatisticsCard from "components/Cards/StatisticsCards/MiniStatisticsCard";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { onValue, ref } from "firebase/database";
import { firebaseDB } from "global/Firebase";
import { CustomDescrypt } from "global/func";
import { getDataWithResponse } from "global/getFunc";
import MaterialReactTable from "material-react-table";
import BarDrilldownCaleg from "myComponent/Charts/BarDrilldownCaleg";
import BarDrilldownDPR from "myComponent/Charts/BarDrilldownDPR";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import userStore from "store/userStore";

const DataRealCount = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [tableData, setTableData] = useState({});
    const [loading, setLoading] = useState(false)
    const [listPsu, setListPsu] = useState([])

    const [parpolData, setParpolData] = useState([])
    const [selectedParpol, setSelectedParpol] = useState(0)

    useEffect(() => {
        console.log(tableData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData])

    const db = firebaseDB
    const pilpres = ref(db, 'real-count');

    useEffect(() => {
        onValue(pilpres, () => {
            requestData()
            requestPSU()
            requestPerolehanCaleg()
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const requestData = () => {
        getDataWithResponse(
            '/admin/perolehan/' + 4 + '/' + 0,
            decryptedUser,
            null,
            setTableData,
            logOut
        );
    }

    const requestPSU = () => {
        getDataWithResponse(
            '/admin/psu-rc',
            decryptedUser,
            setLoading,
            setListPsu,
            logOut
        )
    }

    const requestPerolehanCaleg = () => {
        getDataWithResponse(
            `/admin/perolehan-caleg/${selectedParpol}/4/0`,
            decryptedUser,
            setLoading,
            setParpolData,
            logOut
        )
    }

    useEffect(() => {
        selectedParpol !== 0 && requestPerolehanCaleg()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedParpol])

    const columns = [
        {
            accessorKey: 'id',
            header: 'ID',
            size: 50,
            Cell: ({ cell }) => {
                switch (cell.row.original.update_rc) {
                    case null:
                        return cell.row.original.id

                    default:
                        return <Chip label={cell.row.original.id} color="success" />
                }
            }
        },
        {
            accessorKey: 'nm_kab',
            header: 'Kabupaten',
            filterVariant: 'text',
        },
        {
            accessorKey: 'nm_kec',
            header: 'Kecamatan'
        },
        {
            accessorKey: 'nm_kel',
            header: 'Desa/Kelurahan'
        },
        {
            accessorKey: 'tps',
            header: 'TPS'
        },
        {
            accessorKey: 'entry',
            header: 'Nama'
        },
        {
            accessorKey: 'update_rc',
            header: 'Waktu Input',
        },
    ]

    return (
        <SoftBox my={3}>
            <Grid container spacing={3} mb={3} justifyContent='center'>
                <Grid item xs={12} lg={6}>
                    <MiniStatisticsCard
                        title={{ text: "Data Masuk (PSU)" }}
                        count={tableData.jumlahPsu ? tableData.jumlahPsu.length : 0}
                        // percentage={{ color: "success", text: "+55%" }}
                        icon={{ color: "info", component: "paid" }}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <MiniStatisticsCard
                        title={{ text: "Total Suara" }}
                        count={parseInt(tableData.totalSuara)}
                        // percentage={{ color: "success", text: "+55%" }}
                        icon={{ color: "info", component: "paid" }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3} mb={3}>
                <Grid item xs={12} lg={12}>
                    <Card sx={{ p: 1 }}>
                        <BarDrilldownDPR
                            kecamatans={tableData.data}
                            title='Real Count DPR D Sulawesi Tenggara Dapil 1'
                        />
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={3} mb={3}>
                <Grid item xs={12} lg={12}>
                    <Card>
                        <CardContent>
                            <Grid container justifyContent="space-between" >
                                <Grid item xs={12}>
                                    <SoftBox mb={2}>
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                List Parpol
                                            </SoftTypography>
                                        </SoftBox>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedParpol}
                                                label="Role"
                                                onChange={(e) => setSelectedParpol(e.target.value)}
                                            >
                                                {
                                                    tableData.data?.length > 0 && tableData.data.map((parpol) => (
                                                        <MenuItem value={parpol.id}>{parpol.name}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </SoftBox>
                                </Grid>
                                {
                                    selectedParpol !== 0 &&
                                    <Grid item xs={12}>
                                        <BarDrilldownCaleg
                                            kecamatans={parpolData}
                                            title='Perolehan Suara DPR RI daerah Pemilihan SULTRA'
                                            total={parseInt(tableData.totalSuara)}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                    <Card>
                        <CardHeader
                            title={
                                <Typography>
                                    List PSU
                                </Typography>
                            }
                        />
                        <CardContent>
                            <Grid container justifyContent="space-between" >
                                <Grid item xs={12}>
                                    <MaterialReactTable
                                        columns={columns}
                                        data={listPsu}
                                        enableRowActions
                                        // enablePagination={false}
                                        renderRowActions={({ row, table }) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                                                <IconButton
                                                    color="info"
                                                    component={Link}
                                                    to='admin/data-pilpres/detail'
                                                    state={row.original}
                                                >
                                                    <Info />
                                                </IconButton>
                                            </Box>
                                        )}

                                        muiTablePaginationProps={{
                                            rowsPerPageOptions: [10, 20, 50, { label: 'All', value: listPsu.length }],
                                            showFirstButton: true,
                                            showLastButton: true,
                                        }}

                                        state={{
                                            isLoading: loading,
                                            showProgressBars: loading
                                        }}
                                        muiCircularProgressProps={{
                                            color: 'secondary',
                                            thickness: 5,
                                            size: 55,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

export default DataRealCount