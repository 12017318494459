import { BarChart, Home, Person, Place } from "@mui/icons-material";

const adminRoutes = [
    {
        type: "route",
        name: "Dashboard",
        key: [''],
        route: "/admin",
        icon: <Home size="12px" color="warning" />,
        noCollapse: true,
        level: 'all'
    },
    {
        type: "route",
        name: "Hasil Perhitungan",
        key: ['data-dpr'],
        route: "/admin/data-dpr",
        icon: <BarChart size="12px" color="info" />,
        noCollapse: true,
        level: 'all'
    },
    {
        type: "route",
        name: "PSU",
        key: ['psu'],
        route: "/admin/psu",
        icon: <Place size="12px" color="success" />,
        noCollapse: true,
        level: 'all'
    },
    { type: "divider", title: "Account Pages", key: "settings-divider" },
    { type: "title", title: "Settings", key: "settings" },
    {
        type: "route",
        name: "Admin",
        key: ['/admin/admin'],
        route: "/admin/admin",
        icon: <Person size="12px" color="error" />,
        noCollapse: true,
        level: 'all'
    }
];

export default adminRoutes;
