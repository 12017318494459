import { Groups, Home } from "@mui/icons-material";
import { decodeToken } from "react-jwt";

const entryRoutes = (params) => {
    const usr = decodeToken(params);
    return ([
        {
            type: "route",
            name: "Dashboard",
            key: [''],
            route: "/entry",
            icon: <Home size="12px" color="warning" />,
            noCollapse: true,
            level: 'all'
        },
        {
            type: "route",
            name: "DPR RI",
            key: ['dpr-ri'],
            route: "/entry/dpr-ri",
            icon: <Groups size="12px" color="success" />,
            noCollapse: true,
            level: 'ri',
            tps: usr.tps,
            pemilihan: 2
        },
    ])
}

export default entryRoutes;