import { useState, useEffect } from "react";

// react-router components
import { useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import Breadcrumbs from "../Breadcrumbs";

// Custom styles for DashboardNavbar
import {
    navbar,
    navbarContainer,
    navbarRow,
    navbarIconButton,
    navbarMobileMenu,
} from "./styles";

// Soft UI Dashboard React context
import {
    useSoftUIController,
    setTransparentNavbar,
    setMiniSidenav,
    setOpenConfigurator,
} from "context";

// Images
import { Home } from "@mui/icons-material";
// import userStore from "assets/store/userStore";
import userStore from "store/userStore";

function DashboardNavbar({ absolute, light, isMini, homePage }) {
    const [navbarType, setNavbarType] = useState();
    const [controller, dispatch] = useSoftUIController();
    const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
    // const [openMenu, setOpenMenu] = useState(false);
    const route = useLocation().pathname.split("/").slice(1);
    const logOut = userStore((state) => state.logOut)
    const navigate = useNavigate();

    // console.log("ismini = " + isMini);
    // console.log(arrRoutes);
    // console.log(route);

    useEffect(() => {
        // Setting the navbar type
        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }

        // A function that sets the transparent state of the navbar.
        function handleTransparentNavbar() {
            setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);

        }

        /** 
         The event listener that's calling the handleTransparentNavbar function when 
         scrolling the window.
        */
        window.addEventListener("scroll", handleTransparentNavbar);

        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();

        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", handleTransparentNavbar);
    }, [dispatch, fixedNavbar]);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

    const handleLogout = () => {
        logOut();
        navigate("/", { replace: true });
    }

    return (
        <AppBar
            position={absolute ? "absolute" : navbarType}
            color="inherit"
            sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
        >
            <Toolbar sx={(theme) => navbarContainer(theme)}>
                <SoftBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
                    <IconButton
                        // size="small"
                        color="text"
                        sx={navbarMobileMenu}
                        onClick={handleMiniSidenav}
                    >
                        {/* {isMini ? <MenuOpen /> : <Menu />} */}
                        <Icon color="text">
                            {miniSidenav ? "menu_open" : "menu"}
                        </Icon>
                    </IconButton>
                    <Breadcrumbs icon={<Home />} title={route.length > 1 ? route[route.length - 1] : "Dashboard"} route={route} light={light} home={homePage} />
                </SoftBox>

                <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
                    {/* <SoftBox pr={1}>
                        <SoftInput
                                placeholder="Type here..."
                                icon={{ component: <Search />, direction: "left" }}
                            />
                    </SoftBox> */}
                    <SoftBox color="text">
                        {/* <Link to="/admin/dashboard"> */}

                        {/* </Link> */}
                        {/* <IconButton
                            size="small"
                            color="inherit"
                            sx={navbarMobileMenu}
                            onClick={handleMiniSidenav}
                        >
                            <Icon className={light ? "text-white" : "text-dark"}>
                                {miniSidenav ? "menu_open" : "menu"}
                            </Icon>
                        </IconButton> */}
                        <IconButton
                            size="small"
                            color="inherit"
                            sx={navbarIconButton}
                            onClick={handleConfiguratorOpen}
                        >
                            <Icon>settings</Icon>
                        </IconButton>
                        {/* <IconButton
                            size="small"
                            color="inherit"
                            sx={navbarIconButton}
                            aria-controls="notification-menu"
                            aria-haspopup="true"
                            variant="contained"
                            onClick={handleOpenMenu}
                        >
                            <Icon className={light ? "text-white" : "text-dark"}>notifications</Icon>
                        </IconButton> */}
                        <IconButton sx={navbarIconButton} size="small" onClick={handleLogout}>
                            <Icon
                                sx={({ palette: { text, white } }) => ({
                                    color: text.main,
                                })}
                            >
                                logout
                            </Icon>
                            <SoftTypography
                                variant="button"
                                fontWeight="medium"
                                color='text'
                            >
                                Log Out
                            </SoftTypography>
                        </IconButton>
                        {/* {renderMenu()} */}
                    </SoftBox>
                </SoftBox>
            </Toolbar>
        </AppBar>
    );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
    absolute: false,
    light: false,
    isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
    absolute: PropTypes.bool,
    light: PropTypes.bool,
    isMini: PropTypes.bool,
    homePage: PropTypes.string.isRequired
};

export default DashboardNavbar;
