import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material"
import { CustomDescrypt } from "global/func";
import { decodeToken } from "react-jwt";

const Dashboard = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const user = decodeToken(decryptedUser)

    return (
        <Grid container spacing={3} justifyContent='center' py={2}>
            <Grid item xs={12} lg={12}>
                <Card>
                    <CardHeader
                        title="Informasi TPS"
                    />
                    <CardContent>
                        <Grid container >
                            <Grid item xs={12} mb={1} >
                                <Typography variant='body2'>{user?.tps?.nm_kab || ''}</Typography>
                            </Grid>
                            <Grid item xs={12} mb={1}>
                                <Typography variant='body2'>Kecamatan {user?.tps?.nm_kec || ''}</Typography>
                            </Grid>
                            <Grid item xs={12} mb={1}>
                                <Typography variant='body2'>Kelurahan {user?.tps?.nm_kel || ''}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='body2'>{user?.tps?.tps || ''}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='body2'>Relawan : {user?.nama || ''}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='body2'>No. HP : {user?.no_hp || ''}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='body2'>Kode PSU : {user?.kodePsu || ''}</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default Dashboard