import { Box, Card, CardContent, CardHeader, Grid, IconButton, Skeleton, Typography } from "@mui/material"
import SoftBox from "components/SoftBox"
import SoftTypography from "components/SoftTypography"
import { CustomDescrypt } from "global/func"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import userStore from "store/userStore"
import SoftInput from "components/SoftInput"
import { getDataWithResponse } from "global/getFunc"
import { getGambarCapres } from "global/func"
import { ArrowBack } from "@mui/icons-material"

const Detail = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)
    const navigate = useNavigate();

    const location = useLocation()
    const data = location.state

    const [listParpol, setListParpol] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        reqCalon()
        console.log(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        console.log(listParpol);
    }, [listParpol])

    const reqCalon = () => {
        getDataWithResponse(
            '/get-calon-by-pemilihan/1/' + data.id,
            decryptedUser,
            setLoading,
            setListParpol,
            logOut
        );
    }

    return (
        <SoftBox my={3} >
            <Grid container spacing={3} justifyContent='center' pb={2}>
                <Grid item xs={12} lg={12}>
                    <Card>
                        <CardHeader
                            avatar={
                                <IconButton onClick={() => navigate(-1)}>
                                    <ArrowBack />
                                </IconButton>
                            }
                            title="Kembali"
                        />
                        <CardContent>
                            <Grid container >
                                <Grid item xs={12} mb={1}>
                                    <Typography variant='body2'>{data.nm_kab || ''}</Typography>
                                </Grid>
                                <Grid item xs={12} mb={1}>
                                    <Typography variant='body2'>Kecamatan {data.nm_kec || ''}</Typography>
                                </Grid>
                                <Grid item xs={12} mb={1}>
                                    <Typography variant='body2'>Kelurahan {data.nm_kel || ''}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='body2'>{data.tps || ''}</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {
                loading &&
                (
                    <Grid container spacing={3} justifyContent='center' mb={2}>
                        <Grid item xs={12} lg={3}>
                            <Skeleton variant="rectangular" width='100%' height={118} />
                            <Box sx={{ pt: 0.5 }}>
                                <Skeleton />
                                <Skeleton width="60%" />
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Skeleton variant="rectangular" width='100%' height={118} />
                            <Box sx={{ pt: 0.5 }}>
                                <Skeleton />
                                <Skeleton width="60%" />
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Skeleton variant="rectangular" width='100%' height={118} />
                            <Box sx={{ pt: 0.5 }}>
                                <Skeleton />
                                <Skeleton width="60%" />
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Skeleton variant="rectangular" width='100%' height={118} />
                            <Box sx={{ pt: 0.5 }}>
                                <Skeleton />
                                <Skeleton width="60%" />
                            </Box>
                        </Grid>
                    </Grid>
                )
            }
            <Grid container spacing={3} >
                {
                    listParpol.map((parpol, index) => (
                        <Grid item lg={12} key={index}>
                            <Grid container spacing={3}>
                                {
                                    parpol.calon.map((calon, i) => (
                                        <Grid item xs={12} lg={4} key={i}>
                                            <Card>
                                                <CardContent>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <img
                                                                src={getGambarCapres(calon.id)}
                                                                alt='Gambar Parpol'
                                                                style={{
                                                                    objectFit: 'contain',
                                                                    height: '200px',
                                                                    width: '100%'
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <SoftBox justifyContent="center" key={i} pb={3}>
                                                        <SoftBox>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                {calon.no_urut + '. ' + calon.nama}
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="number"
                                                            defaultValue={calon.nilai || ''}
                                                            autoComplete='off'
                                                            disabled
                                                            error={calon.nilai !== '0'}
                                                        />
                                                    </SoftBox>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))
                                }
                                <Grid item xs={12} lg={12}>
                                    <Grid container spacing={2} justifyContent='center'>
                                        <Grid item xs={12} sm={12} lg={12}>
                                            <Card>
                                                <CardContent sx={{ pb: '.5rem !important' }}>
                                                    <SoftBox justifyContent="center" pb={3}>
                                                        <SoftBox>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Total Suara Partai
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="number"
                                                            defaultValue={parpol.total || '0'}
                                                            autoComplete='off'
                                                            disabled
                                                            error={parpol.total !== '0'}
                                                        />
                                                    </SoftBox>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))
                }
            </Grid>
        </SoftBox >
    )
}

export default Detail