import { Backdrop, Box, Button, Card, CardContent, CardHeader, CircularProgress, Grid, Skeleton, Typography } from "@mui/material"
import SoftBox from "components/SoftBox"
import SoftTypography from "components/SoftTypography"
import { CustomDescrypt } from "global/func"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import userStore from "store/userStore"
import { getGambarParpol } from "global/func"
import SoftInput from "components/SoftInput"
import { LoadingButton } from "@mui/lab"
import { EditOutlined, Save } from "@mui/icons-material"
import { getDataWithResponse } from "global/getFunc"
import { decodeToken } from "react-jwt"
import { postSubmit } from "global/postFunc"
import { updateFirebaseDPRD } from "global/Firebase"
import Swal from "sweetalert2"
import { updateFirebaseRealCount } from "global/Firebase"

const InputDataDPRProv = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)
    const user = decodeToken(decryptedUser)

    const location = useLocation()
    const data = location.state

    const [listParpol, setListParpol] = useState([])
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [editMode, setEditMode] = useState(false)

    useEffect(() => {
        reqCalon()
        console.log(data);
        console.log(user);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        console.log(listParpol);
    }, [listParpol])

    const reqCalon = () => {
        getDataWithResponse(
            '/get-calon-by-pemilihan/' + data + '/' + user.tps.id,
            decryptedUser,
            setLoading,
            setListParpol,
            logOut
        );
    }

    const submit = (event) => {
        event.preventDefault();
        let salah = [];
        listParpol.forEach((parpol) => {
            let jumlah = 0;
            // eslint-disable-next-line array-callback-return
            parpol.calon.map((caleg) => {
                jumlah  = jumlah + parseInt(caleg.nilai)
                console.log(jumlah)
            })
            if (jumlah !== parseInt(parpol.total)) {
                salah.push(parpol)
            }
        })
        if (salah.length === 0) {
            postSubmit(
                '/entry/perolehan/insert',
                {
                    tps: user.tps.id,
                    perolehan: listParpol,
                    qc: user.tps.prov,
                    pemilihan: data
                },
                decryptedUser,
                setSaving,
                afterSubmit,
                logOut
            )
        }
        else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `Total Suara Partai ${salah[0].nama} tidak Sesuai`
            })
        }
    }

    const afterSubmit = () => {
        setSaving(false)
        setEditMode(false)
        const now = new Date()
        updateFirebaseRealCount(user.tps.id, now.getTime())
        if (user.tps.prov === '1') {
            updateFirebaseDPRD(user.tps.id, now.getTime())
        }
    }

    return (
        <SoftBox my={3} component='form' onSubmit={submit}>
            <Grid container spacing={3} justifyContent='center' pb={2}>
                <Grid item xs={12} lg={12}>
                    <Card>
                        <CardHeader
                            title="Input Data"
                            action={
                                <Button component="label" variant="outlined" startIcon={<EditOutlined />} onClick={() => setEditMode(true)}>
                                    Edit
                                </Button>
                            }
                        />
                        <CardContent>
                            <Grid container >
                                <Grid item xs={12} mb={1}>
                                    <Typography variant='body2'>{user?.tps?.nm_kab || ''}</Typography>
                                </Grid>
                                <Grid item xs={12} mb={1}>
                                    <Typography variant='body2'>Kecamatan {user?.tps?.nm_kec || ''}</Typography>
                                </Grid>
                                <Grid item xs={12} mb={1}>
                                    <Typography variant='body2'>Kelurahan {user?.tps?.nm_kel || ''}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='body2'>{user?.tps?.tps || ''}</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {
                loading &&
                (
                    <Grid container spacing={3} justifyContent='center' mb={2}>
                        <Grid item xs={12} lg={3}>
                            <Skeleton variant="rectangular" width='100%' height={118} />
                            <Box sx={{ pt: 0.5 }}>
                                <Skeleton />
                                <Skeleton width="60%" />
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Skeleton variant="rectangular" width='100%' height={118} />
                            <Box sx={{ pt: 0.5 }}>
                                <Skeleton />
                                <Skeleton width="60%" />
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Skeleton variant="rectangular" width='100%' height={118} />
                            <Box sx={{ pt: 0.5 }}>
                                <Skeleton />
                                <Skeleton width="60%" />
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Skeleton variant="rectangular" width='100%' height={118} />
                            <Box sx={{ pt: 0.5 }}>
                                <Skeleton />
                                <Skeleton width="60%" />
                            </Box>
                        </Grid>
                    </Grid>
                )
            }
            <Grid container spacing={3} >
                {
                    listParpol.map((parpol, index) => (
                        <Grid item xs={12} lg={3} key={index}>
                            <Card
                            >
                                <CardContent>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <img
                                                src={getGambarParpol(parpol.id)}
                                                alt='Gambar Parpol'
                                                style={{
                                                    objectFit: 'contain',
                                                    height: '200px',
                                                    width: '100%'
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='body2'>{parpol.id + '. ' + parpol.nama}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SoftBox justifyContent="center" pb={3}>
                                                <SoftBox>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Total Suara Partai
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput
                                                    type="number"
                                                    defaultValue={parpol.total || '0'}
                                                    onChange={(event) => {
                                                        setListParpol(parpol => {
                                                            const copy = [...parpol]
                                                            copy[index].total = event.target.value
                                                            return copy
                                                        })
                                                    }}
                                                    autoComplete='off'
                                                    disabled={!editMode}
                                                />
                                            </SoftBox>
                                        </Grid>
                                    </Grid>
                                    {
                                        parpol.calon.map((calon, i) => (
                                            calon.no_urut === '0' ?
                                                <SoftBox justifyContent="center" key={i} pb={3}>
                                                    <SoftBox>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            {calon.nama}
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <SoftInput
                                                        type="number"
                                                        defaultValue={calon.nilai || ''}
                                                        onChange={(event) => {
                                                            setListParpol(parpol => {
                                                                const copy = [...parpol]
                                                                copy[index].calon[i].nilai = event.target.value
                                                                return copy
                                                            })
                                                        }}
                                                        autoComplete='off'
                                                        disabled={!editMode}
                                                    />
                                                </SoftBox>
                                                :
                                                <SoftBox justifyContent="center" key={i}>
                                                    <SoftBox>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            {calon.no_urut + '. ' + calon.nama}
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <SoftInput
                                                        type="number"
                                                        defaultValue={calon.nilai || ''}
                                                        onChange={(event) => {
                                                            setListParpol(parpol => {
                                                                const copy = [...parpol]
                                                                copy[index].calon[i].nilai = event.target.value
                                                                return copy
                                                            })
                                                        }}
                                                        autoComplete='off'
                                                        disabled={!editMode}
                                                    />
                                                </SoftBox>
                                        ))
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                }
            </Grid>
            <Grid container spacing={2} justifyContent='center' my={1}>
                <Grid item xs={12} sm={12} lg={12}>
                    <Card>
                        <CardContent sx={{ pb: '.5rem !important' }}>
                            <Grid container justifyContent='flex-start' py={1} >
                                <Grid item>
                                    <SoftBox>
                                        <LoadingButton
                                            loading={saving}
                                            loadingPosition="start"
                                            startIcon={<Save />}
                                            variant="contained"
                                            type='submit'
                                            disabled={!editMode}
                                        >
                                            Save
                                        </LoadingButton>
                                    </SoftBox>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={saving}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </SoftBox >
    )
}

export default InputDataDPRProv