import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@mui/icons-material';
import { Box, IconButton, TableCell, TableRow, styled, tableCellClasses } from '@mui/material';
import pkb from '../assets/images/parpol/01_pkb.png'
import gerindra from '../assets/images/parpol/02_gerindra.png'
import pdip from '../assets/images/parpol/03_pdip.png'
import golkar from '../assets/images/parpol/04_golkar.png'
import nasdem from '../assets/images/parpol/05_nasdem.png'
import partaiburuh from '../assets/images/parpol/06_partaiburuh.png'
import gelora from '../assets/images/parpol/07_gelora.png'
import pks from '../assets/images/parpol/08_pks.png'
import pkn from '../assets/images/parpol/09_pkn.png'
import hanura from '../assets/images/parpol/10_hanura.png'
import garuda from '../assets/images/parpol/11_garuda.png'
import pan from '../assets/images/parpol/12_pan.png'
import pbb from '../assets/images/parpol/13_pbb.png'
import demokrat from '../assets/images/parpol/14_demokrat.png'
import psi from '../assets/images/parpol/15_psi.png'
import perindo from '../assets/images/parpol/16_perindo.png'
import ppp from '../assets/images/parpol/17_ppp.png'
import ummat from '../assets/images/parpol/18_ummat.png'

import capres1 from '../assets/images/capres/1.png'
import capres2 from '../assets/images/capres/2.png'
import capres3 from '../assets/images/capres/3.png'

import dpd1 from '../assets/images/dpd/126.png'
import dpd2 from '../assets/images/dpd/127.png'
import dpd3 from '../assets/images/dpd/128.png'
import dpd4 from '../assets/images/dpd/129.png'
import dpd5 from '../assets/images/dpd/130.png'
import dpd6 from '../assets/images/dpd/131.png'
import dpd7 from '../assets/images/dpd/132.png'
import dpd8 from '../assets/images/dpd/133.png'
import dpd9 from '../assets/images/dpd/134.png'
import dpd10 from '../assets/images/dpd/135.png'
import dpd11 from '../assets/images/dpd/136.png'
import dpd12 from '../assets/images/dpd/137.png'
import dpd13 from '../assets/images/dpd/138.png'
import dpd14 from '../assets/images/dpd/139.png'
import dpd15 from '../assets/images/dpd/140.png'
import dpd16 from '../assets/images/dpd/141.png'
import dpd17 from '../assets/images/dpd/142.png'
import dpd18 from '../assets/images/dpd/143.png'
import dpd19 from '../assets/images/dpd/144.png'
import dpd20 from '../assets/images/dpd/145.png'
import dpd21 from '../assets/images/dpd/146.png'
import dpd22 from '../assets/images/dpd/147.png'
import dpd23 from '../assets/images/dpd/148.png'
import dpd24 from '../assets/images/dpd/149.png'

function CustomEncrypt(text) {
    let key = "6Le0DgMTAs";
    const CryptoJS = require('crypto-js');
    var encryptedString = CryptoJS.AES.encrypt(text, key).toString();

    return encryptedString;
}

function CustomDescrypt(text) {
    let key = "6Le0DgMTAs";
    const CryptoJS = require('crypto-js');

    let bytes = CryptoJS.AES.decrypt(text, key);
    let data = bytes.toString(CryptoJS.enc.Utf8);

    return data;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function TablePaginationActions(props) {
    // const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                <FirstPage />
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                <LastPage />
            </IconButton>
        </Box>
    );
}

function getGambarParpol(props) {
    let gambar;
    switch (props) {
        case '1':
            gambar = pkb
            break;

        case '2':
            gambar = gerindra
            break;

        case '3':
            gambar = pdip
            break;

        case '4':
            gambar = golkar
            break;

        case '5':
            gambar = nasdem
            break;

        case '6':
            gambar = partaiburuh
            break;

        case '7':
            gambar = gelora
            break;

        case '8':
            gambar = pks
            break;

        case '9':
            gambar = pkn
            break;

        case '10':
            gambar = hanura
            break;

        case '11':
            gambar = garuda
            break;

        case '12':
            gambar = pan
            break;

        case '13':
            gambar = pbb
            break;

        case '14':
            gambar = demokrat
            break;

        case '15':
            gambar = psi
            break;

        case '16':
            gambar = perindo
            break;

        case '17':
            gambar = ppp
            break;

        case '18':
            gambar = ummat
            break;

        default:
            gambar = pkb;
    }
    return gambar;
}

function getGambarCapres(params) {
    let gambar;

    switch (params) {
        case '1':
            gambar = capres1;
            break;

        case '2':
            gambar = capres2;
            break;

        case '3':
            gambar = capres3;
            break;

        default:
            break;
    }
    return gambar;
}

function getGambarDPD(params) {
    let gambar;

    switch (params) {
        case '126':
            gambar = dpd1;
            break;

        case '127':
            gambar = dpd2;
            break;

        case '128':
            gambar = dpd3;
            break;

        case '129':
            gambar = dpd4;
            break;

        case '130':
            gambar = dpd5;
            break;

        case '131':
            gambar = dpd6;
            break;

        case '132':
            gambar = dpd7;
            break;

        case '133':
            gambar = dpd8;
            break;

        case '134':
            gambar = dpd9;
            break;

        case '135':
            gambar = dpd10;
            break;

        case '136':
            gambar = dpd11;
            break;

        case '137':
            gambar = dpd12;
            break;

        case '138':
            gambar = dpd13;
            break;

        case '139':
            gambar = dpd14;
            break;

        case '140':
            gambar = dpd15;
            break;

        case '141':
            gambar = dpd16;
            break;

        case '142':
            gambar = dpd17;
            break;

        case '143':
            gambar = dpd18;
            break;

        case '144':
            gambar = dpd19;
            break;

        case '145':
            gambar = dpd20;
            break;

        case '146':
            gambar = dpd21;
            break;

        case '147':
            gambar = dpd22;
            break;

        case '148':
            gambar = dpd23;
            break;

        case '149':
            gambar = dpd24;
            break;

        default:
            break;
    }
    return gambar;
}

export { CustomEncrypt, CustomDescrypt, StyledTableCell, StyledTableRow, TablePaginationActions, getGambarParpol, getGambarCapres, getGambarDPD }