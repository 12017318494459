import { initializeApp } from "firebase/app";
import { getDatabase, ref, set } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyB5djenVKlsguBbZmOmCpKnjU-rO-gLBbQ",
    authDomain: "qcpemilu2024.firebaseapp.com",
    databaseURL: "https://qcpemilu2024-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "qcpemilu2024",
    storageBucket: "qcpemilu2024.appspot.com",
    messagingSenderId: "25261282139",
    appId: "1:25261282139:web:3368a41849aea13d97407e",
    measurementId: "G-RY5PQCH4T6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firebaseDB = getDatabase(app)

// firebase.initializeApp(firebaseConfig);
// const firebaseDB = firebase.database();

const updateFirebasePilpres = (params1, params2) => {
    const db = getDatabase()
    set(ref(db, '/pilpres/'), {
        [params1]: params2
    })
}

const updateFirebaseDPD = (params1, params2) => {
    const db = getDatabase()
    set(ref(db, '/dpd/'), {
        [params1]: params2
    })
}

const updateFirebaseDPR = (params1, params2) => {
    const db = getDatabase()
    set(ref(db, '/dpr/'), {
        [params1]: params2
    })
}

const updateFirebaseDPRD = (params1, params2) => {
    const db = getDatabase()
    set(ref(db, '/dprd/'), {
        [params1]: params2
    })
}

const updateFirebaseRealCount = (params1, params2) => {
    const db = getDatabase()
    set(ref(db, '/real-count/'), {
        [params1]: params2
    })
}

// const updateFirebasePencairan = (params, progres) => {
//     params.progres = progres
//     const id_opd = params.id_opd
//     const db = getDatabase();
//     set(ref(db, '/pencairan/'), {
//         [id_opd]: params
//     });
// }
// const updateFirebasePermintaan = (params, progres) => {
//     params.progres = progres
//     const db = getDatabase();
//     set(ref(db, '/permintaan/'), {
//         permintaan: params
//     });
// }

export { firebaseDB, updateFirebasePilpres, updateFirebaseDPD, updateFirebaseDPR, updateFirebaseDPRD, updateFirebaseRealCount }